;(function() {
"use strict";

const showDiagnosticsComponent = {
    templateUrl: 'components/show-diagnostics/show-diagnostics.component.html',
    controller: 'ShowDiagnosticsController',
    controllerAs: 'vm',
};

function showDiagnosticsController($location, $log, $state, $window) {
    let vm = this;
    vm.version = window.tmr.code_version;

    vm.today = new Date();

    vm.timeZoneFormatted = jstz.determine().name();
    vm.userAgent = navigator.userAgent;

    vm.host = $location.host();

    if (vm.userAgent.toLowerCase().indexOf(' electron/') > -1) {
        vm.electronVersion = require('electron').remote.app.getVersion();
    }

    vm.$onInit = function () {
    };

    vm.$onChanges = function (changedObj) {
    };

    vm.back = function(){
        $window.history.back();
    }

    // -------------------Getters---------------------------

    // -------------------Setters---------------------------
}

angular
    .module('tmr-admin')
    .component('showDiagnostics', showDiagnosticsComponent)
    .controller('ShowDiagnosticsController', showDiagnosticsController)
    ;
}());
